<template>
  <div>
    <v-dialog v-model="dialog" max-width="420" max-height="400" persistent>
      <v-card class="overflow-hidden">
        <v-row class="ma-0 ml-4">
          <span
            class="col-10 px-0 grey--text text--darken-4 text-h6 text-truncate"
            >{{ $t("Reject") }}</span
          >
          <v-btn
            class="col-2 my-auto"
            aria-label="cross"
            icon
            @click="closeDialog()"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>
        <v-row>
          <v-divider></v-divider>
        </v-row>
        <v-row class="ma-0 ml-4 mb-0 pt-4">
          <span class="col-10 px-0 grey--text text--darken-4 text-h6">{{
            $t("Are you sure you want to reject the request ?")
          }}</span>
        </v-row>
        <v-row justify="center" class="mt-0">
          <v-col cols="11">
            <v-textarea
              v-model="comments"
              label="Comments"
              rows="3"
              outlined
              dense
              required
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row class="mb-2">
          <v-divider></v-divider>
        </v-row>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="primary"
            outlined
            class="text-none rounded-lg elevation-0"
            depressed
            width="90"
            @click="closeDialog"
          >
            {{ $t("cancel") }}
          </v-btn>

          <v-btn
            class="primary text-none rounded-lg elevation-0"
            width="90"
            depressed
            @click="rejectTicket"
          >
            {{ $t("Reject") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      default: () => true,
    },
    comments: {
      type: String,
      default: () => "",
    },
  },
  methods: {
    rejectTicket() {
      this.$emit("rejectTicket", { comments: [{ comments: this.comments }] });
    },
    closeDialog() {
      this.$emit("closeDialog");
    },
  },
};
</script>

<style lang="scss" scoped></style>
